import React, { useMemo, useState, useEffect } from 'react';
import {
  Container,
  BtnLogin,
  FeedBackUser,
  LogoImage,
  LoginForm,
} from './loginStyle';

import { useForm } from 'react-hook-form';
import { useAuth } from '../../Context/auth';
import {
  
  DialogContainer,
} from '../../Components/LoginServerDialog';
import Input from '../../Components/Form/Input';
import { SelectInput } from '../../Components/Form/select-input';

export default function Login() {
  //const [feedbackUser, setfeedbackUser] = useState('');
  const { signIn } = useAuth();
  const { register, handleSubmit } = useForm();
  const [errorMsg, setErrorMsg] = useState('');
  // const [isLoading, setLoading] = useState(false);

  async function handleSignIn(data: {
    username: string;
    password: string;
    mac: string;
  }) {
    signIn(data.username, data.password, data.mac);
  }

  return (
    <DialogContainer>
      <Container>
        <LogoImage src={'./logo.png'} alt="logo" />
        <LoginForm onSubmit={handleSubmit(handleSignIn)}>
          {errorMsg && <FeedBackUser>{errorMsg}</FeedBackUser>}
          <Input
            autoComplete="off"
            type="text"
            id="username"
            placeholder="Usuário"
            {...register('username')}
          />
          <Input
            id="password"
            type="password"
            placeholder="Senha"
            {...register('password')}
          />
          <Input
            id="mac_address"
            type="text"
            placeholder="MAC Address"
            {...register('mac')}
          />
          <BtnLogin>Acessar</BtnLogin>
        </LoginForm>

      </Container>
    </DialogContainer>
  );
}
