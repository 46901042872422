import { format } from 'date-fns';
import React from 'react';
import { useState } from 'react';
import { useForm } from 'react-hook-form';
import { IoSearch } from 'react-icons/io5';
import { toast } from 'react-toastify';
import Button from '../../Components/Button';
import Input from '../../Components/Form/Input';
import { Modal } from '../../Components/Modal';
import Table2 from '../../Components/TableV2';
import {
  requestPayment,
  requestSeachTicket,
} from '../../Services/resquestsV5';
import { transformDate } from '../../utils/formatData';
import {
  ButtonPayment,
  DivText,
  SearchContainer,
  SubTittlePayment,
  TittlePayment,
  DivContent,
  TittlePaymentBOLD,
  ContainerPayment,
  TitlePayment,
} from './styles';

function Payment() {
  const { register, handleSubmit } = useForm();
  const [pagamento, setPagamento] = useState<IPagamento>();
  const [valorDigitado, setvalorDigitado] = useState<string>();
  const [openModalPagar, setopenModaPagar] = useState(false);
  const [openModalJaPago, setopenModalJaPago] = useState(false);
  const [bilheteId, setBilheteId] = useState<string>();
  const [jogoId, setJogoId] = useState<number>();

  async function handleVerifBilhete(bilhete?: string) {
    const { data } = await requestSeachTicket(bilhete ? bilhete : '');
    if (data) {
      setPagamento(data);
      if (data?.bilhetes.length == 0) {
        toast.error('Sem prêmios disponível para esse bilhete');
      }
      if (data?.bilhetes[0]?.cartelas[0]?.premio) {
        setopenModalJaPago(true);
      } else {
        setopenModalJaPago(false);
        setJogoId(data?.bilhetes[0]?.cartelas[0]?.sorteio);
        setBilheteId(data?.bilhetes[0]?.bilhete);
      }

    }

  }

  async function handleSignIn(value: { password: string }) {
    if (bilheteId && jogoId) {
      const { data } = await requestPayment(
        jogoId.toString(),
        bilheteId,
        value.password
      );
      if (data) {
        toast.success('pagamento realizado com sucesso!');
        setopenModaPagar(false);
        handleVerifBilhete(bilheteId);
      }         

    }
  }

  return (
    <ContainerPayment>
      <TitlePayment>Pagar prêmio:</TitlePayment>
      <SearchContainer>
        <Input
          placeholder="Digite o numero do bilhete"
          onChange={(e) => setvalorDigitado(e.currentTarget.value.replace(/\D+/g, ''))}
          type='tel'
          value={valorDigitado}
          maxLength={13}
        />
        <Button
          onClick={() => {
            handleVerifBilhete(valorDigitado);
            setJogoId(0);
            setBilheteId('');
          }}
        >
          <IoSearch />
        </Button>
      </SearchContainer>
      {pagamento?.bilhetes.map((value, index) => (
        <div key={index + value?.bilhete}>
          <TitlePayment>Bilhete: {value?.bilhete}</TitlePayment>
          <TitlePayment>Local: {pagamento?.estabelecimento}</TitlePayment>
          {value?.cartelas && (
            <Table2
              columns={{
                comprado_em: 'Data',
                sorteio: 'Sorteio',
                tipo_premio: 'Prêmios',
                valor: 'Valor',
              }}
              data={value?.cartelas.map((value) => {
                const { valor, comprado_em, sorteio, tipo_premio } = value;
                return {
                  comprado_em: format(transformDate(comprado_em), 'dd/MM/yyyy'),
                  sorteio,
                  tipo_premio,
                  valor: valor.toLocaleString('pt-br', {
                      minimumFractionDigits: 2,
                    }),
                };
              })}
            />
          )}
          <TitlePayment>
            Total:{' '}
            {pagamento?.total_premio?.toLocaleString('pt-br', {
              minimumFractionDigits: 2,
            })}
          </TitlePayment>
        </div>
      ))}
      <ButtonPayment
        opacidade={
          bilheteId && !pagamento?.bilhetes[0]?.cartelas[0]?.premio
            ? '1.0'
            : '0.5'
        }
        mouseEvent={
          bilheteId && !pagamento?.bilhetes[0]?.cartelas[0]?.premio
            ? 'all'
            : 'none'
        }
        onClick={() => {
          bilheteId && setopenModaPagar(!openModalPagar);
        }}
      >
        {pagamento?.bilhetes[0]?.cartelas[0]?.premio ? 'Já está pago' : 'Pagar'}
      </ButtonPayment>
      {openModalJaPago && (
        <Modal
          modalVisible={() => setopenModalJaPago(false)}
          title={'Bilhete já pago'}
        >
          {pagamento?.bilhetes[0]?.cartelas.map((value, index) => (
            <DivContent key={value.linha1_lista + '_' + index}>
              <DivText>
                <TittlePaymentBOLD>{value?.tipo_premio} </TittlePaymentBOLD>
              </DivText>
              <DivText>
                <TittlePayment>Data: </TittlePayment>
                <SubTittlePayment>
                  {format(
                    transformDate(
                      value?.premio?.datahora ? value?.premio?.datahora : ''
                    ),
                    'dd/MM/yyyy  HH:mm:ss'
                  )}
                </SubTittlePayment>
              </DivText>
              <DivText>
                <TittlePayment>Pago por:</TittlePayment>
                <SubTittlePayment>{value?.premio?.pago_por}</SubTittlePayment>
              </DivText>
              <DivText>
                <TittlePayment>Valor pago: </TittlePayment>
                <SubTittlePayment>
                  {'R$' +
                    value?.premio?.valor_pago.toLocaleString('pt-br', {
                      minimumFractionDigits: 2,
                    })}
                </SubTittlePayment>
              </DivText>
            </DivContent>
          ))}
        </Modal>
      )}
      {openModalPagar && (
        <Modal
          modalVisible={() => setopenModaPagar(false)}
          title={'Confirme sua senha'}
        >
          <form onSubmit={handleSubmit(handleSignIn)}>
            <Input
              placeholder="Senha"
              type="password"
              {...register('password')}
            />
            <Button>Login</Button>
          </form>
        </Modal>
      )}
    </ContainerPayment>
  );
}

export default Payment;
