import React from 'react';

import NavBar from '../NavBar';
import Pk from '../../../package.json';
import { Container } from './styles';
import { useAuth } from '../../Context/auth';

function Header() {
  const { user } = useAuth();
  const server = process.env.REACT_APP_SERVER;
  const domain = process.env.REACT_APP_DOMAIN;

  return (
    <Container>
      <div>
        <NavBar />
      </div>
      <span>

        <h2>
          {domain} {Pk?.version}r {server}
        </h2>
        <small>{user?.pos.nome}</small>

      </span>
      {/* <Button style={{color: 'transparent', background: 'transparent'}} >
            <IoRefresh />
          </Button> */}
      {/* <></> */}
    </Container>
  );
}

export default Header;
