import axios, { AxiosError, AxiosResponse } from 'axios';

const BASE_URL_v1 = 'https://hub.mrkeno.app/api/v1';
const  BASE_DOMAIN = process.env.REACT_APP_DOMAIN;
const  BASE_SERVER = process.env.REACT_APP_SERVER;
const  BASE_ENDPOINT = 'https://'+BASE_SERVER+"." + BASE_DOMAIN + '/api';

export const v5 = axios.create({
  baseURL: `${BASE_ENDPOINT}/v5`,
  headers: { "Content-Type": "application/json" }
});

export const api_url = axios.create({
  baseURL: BASE_URL_v1,
});
const user = window.localStorage.getItem('@GDSK:user') ? window.localStorage.getItem('@GDSK:user') : undefined;


v5.defaults.headers.Authorization = user ? "Token " + JSON.parse(user).token : undefined;
v5.defaults.headers.common.Authorization = user ? JSON.parse(user).session_id : undefined;
v5.defaults.headers.common.sessao = user ? JSON.parse(user).session_id : undefined;

export const setToken = (token: string, sessao?: string) => {
  const Token = token ? `Token ${token}` : undefined;
  const session = sessao || undefined;

  v5.defaults.headers.Authorization = Token;
  v5.defaults.headers.common.Authorization = v5.defaults.headers.sessao = session;
  v5.defaults.headers.common.sessao = session;
};

const servidores = [
  'painel',
  'automatus',
  'devs1',
  'devs2',
  'devs3',
  'devs4',
];

export const transformServer = (x: string): IServer => ({
  id: x + Math.floor(Math.random() * 101),
  serverName: x,
  subDomain: x,
  slug: x
});

export const transformClient = (x: { name: string; domain: string; servers?: IServer[] }): Client => ({
  ...x,
  clientName: x.name,
  baseDomain: x.domain,
  id: x.domain + Math.floor(Math.random() * 101),
  servers: x.servers || undefined,
  slug: x.domain.split('.app')[0]
});

export const defaultServers = servidores.map(transformServer);

export const clientes: Client[] = [
  { name: 'Mr Keno', domain: 'mrkeno.app' },
  { name: 'Keno Online', domain: 'kenoonline.app' },
  { name: 'Super Sorte', domain: 'supersorte.app' },
  { name: 'Bin Gold', domain: 'bingold.app' },
  { name: 'Bola 90', domain: 'bola90.app' },
  { name: 'Keno da Sorte', domain: 'kenodasorte.app' }
]
  .map(transformClient)
  .map((x) => ({ ...x, servers: defaultServers }));

const responseInterceptor = (response: AxiosResponse<any>) => {
  if (process.env.NODE_ENV === 'development' && response.data && response.data !== {}) {
    // console.log(response.data);
  }
  return response;
};

const interceptorId: number[] = [];

export const setBaseUrl = (id: string, domain = BASE_DOMAIN) => {
  v5.defaults.baseURL = `https://${id}.${domain}/api/v5`;
};

export const SetOnInvalidSession = (callback: () => void) => {
  const errorInterceptor = (error: AxiosError) => {
    const status = error.response?.status;

    if (process.env.NODE_ENV === 'development') {
      console.log(error.request._url);
      console.log(error.response);
    }

    if (!status) {
      //Alert.alert('Falha de conexão', 'Verifique sua conexão com a internet');
      callback?.();
      return;
    }

    if (status === 401) {
      alert('Sessão Detectada em outro dispositivo');
      callback?.();
      return;
    }

    return Promise.reject(error);
  };
}

export const getPreServer = () => {
  const server = v5.defaults.baseURL?.split('/api')[0].split('//')[1].replace('.app', '').split('.') || '';
  return `${server[0]} ${server[1]}`;
};

export default api_url;
