import styled, { css } from 'styled-components';

export const Container = styled.label`
  display: grid;
  grid-template-columns: 50px 1fr 50px;
  margin-top: 1rem;
  max-height: 4rem;
  height: 4rem;
  transition: 0.6s;
  border: 1px solid ${({ theme }) => theme.colors.secondary};
  background: ${({ theme }) => theme.colors.deep};
  border-radius: 5px; 

  &:focus-within {
    background: white;
    border-color: ${({ theme }) => theme.colors.primary};
  }

  
  
  `;


export const InputComponent = styled.input`
  color: ${({ theme }) => theme.text.input};
  letter-spacing: 0.2px;
  background-color: transparent;
  outline: none;
  max-width: 100px;
  min-height: 2.875rem;
  padding: 0 1rem;
  ::placeholder {
    color: ${({ theme }) => theme.text.placeholder};
  }
  `;


export const ActionButton = styled.button`
  padding: 0.7rem 1rem;
  min-width: 3rem;
  border-radius: 5px;
  color: black;
  outline: none;
  border: none;
  transition-duration: 0.42s;
  /* color: ${({ theme }) => theme.colors.shape}; */
  
  background-color: ${({ theme }) => theme.colors.secondary};
  box-shadow: 0 0 0 1px ${({ theme }) => theme.colors.secondary}A0;
  flex-grow: 0;

  ${props => props.name === 'plus' && css`
  border-bottom-left-radius: 0px;
  border-top-left-radius: 0px;
  `}
  ${props => props.name === 'minus' && css`
  border-bottom-right-radius: 0px;
  border-top-right-radius: 0px;
  `}
  
  &:hover {
    background-color: ${({ theme }) => theme.colors.primary};
    color: ${({ theme }) => theme.colors.shape} !important;
    
    svg {
      color: ${({ theme }) => theme.colors.shape} !important;
      fill: ${({ theme }) => theme.colors.shape} !important;
    }
  }
`
