import { TabsContent } from "@radix-ui/react-tabs";
import styled from "styled-components";

export const Container = styled(TabsContent)`
  display: flex;
  flex-direction: column;

  & > *:not(:last-child) {
    margin-bottom: 1rem;
  }

  & > div {
    display: flex;
    flex: 1;
    justify-content: space-between;
    margin-bottom: 6px !important;
  }

`

export const Form = styled.form`
  display: flex;
  margin-top: 1rem;
  align-items: flex-end;
  
  & > div {
    flex: 1;
    margin-top: 0;
  }
  
  & > button {
    display: flex;
    
    justify-content: center;
    
    margin-left: 8px;
    svg {
      font-size: 1.4rem;
    }
  }

  @media screen and (max-width: 420px) {
    flex-direction: column;
    align-items: stretch;
    & > div {
      flex: 0;
      margin-bottom: 8px;
    }

    & > button {
      margin: 0;
    }
  }
`
export const ButtonSearch = styled.button`
  background: #0000bf;
  border-radius: 5px;
  border: none;
  align-self: 'center';
  margin: 0;
  padding: 0;
  color: white;
  margin-top: 1rem;
  min-width: 3rem;
  height: 3rem;
  max-height: 3rem;
  min-height: 3rem;
  align-items: center;
  @media screen and (max-width: 360px) {
    width: 100%;
    max-width: 100%;
    min-width: 3rem;
    align-self: center;
    
  }
`;