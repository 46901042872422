import React from 'react';

import { Container } from './styles';

interface TableProps {
  values: string[];
}


function TotalTable({ values }: TableProps) {
  //console.log(value)
  //const columns = Object.keys(values);
    
  return (
    <Container>
      <thead>
        <tr>
          {values.map((column) => (
            <th key={column}>{column}</th>
          ))}
        </tr>
      </thead>
   
    </Container>
  );
}

export default TotalTable;
