import styled, { css } from 'styled-components';
import * as separator from '@radix-ui/react-separator';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 1rem;
  padding: 6px 3px;


  & > h4 {
    display: flex;
    flex-wrap: wrap;
    align-self: stretch;
    justify-content: space-between;
    strong {
      margin-left: 3px;
      color: ${({ theme }) => theme.colors.sucess};
    }
  } 

  & > *:not(:first-child) {
    margin-top: 8px;
  }
`;


export const Line = styled.section`
  display: flex;
  width: 100%;
  justify-content: space-between;
  align-items: baseline;


  @media screen and (max-width: 450px) {
    * {
      font-size: 80% !important;
    }
  }

  & > small, p {
    flex: 1;
    text-align: center;
  }

  & > * {
    font-size: 1.03rem;
    color: ${({ theme }) => theme.text.default};
  }
`

export const Separator = styled(separator.Root)`

  background-color: ${({ theme }) => theme.colors.deep};

  ${props => props.orientation === 'horizontal' && css`
    height: 1.25px;
    width: 100%;
  `}
  ${props => props.orientation === 'vertical' && css`
    height: 100%;
    width: 1.25px;
    margin: 0 15px;
  `}
`

export const ColoredText = styled.strong<{ color?: string }>`
  flex: 1;
  text-align: center;
  color: ${props => props.theme.colors[props.color as keyof typeof props.theme.colors] || props.color}
`