import React from 'react';

import ConfirmPreCompraTab from '../../Components/ConfirmPreCompra';
import DonateTab from '../../Components/DonateTab';

import { Tabs, TabsList, TabsTrigger } from '../../Components/TabLayout';
import { MainContent } from './DoacaoStyle';

export default function Login() {
  return (
    <MainContent>
      <Tabs defaultValue="confirmar">
        <TabsList>
          <TabsTrigger value="confirmar">Pré-compra</TabsTrigger>
          <TabsTrigger value="doar">Doar</TabsTrigger>
        </TabsList>
        <ConfirmPreCompraTab value="confirmar" />
        <DonateTab value="doar" />
      </Tabs>
    </MainContent>
  );
}
