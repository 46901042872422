import React from 'react';

import { Container } from './styles';

import { getPreServer } from '../../../Services/Api';
import Carts from '../Carts';

interface IPrintPreSale {
  lote: IBilheteLote;
  userInfo: IUser & DeviceInfo;
  version: string;
}

const PrintPreSale: React.FC<IPrintPreSale> = ({ lote, userInfo, version }) => {
  function formatDate(date: Date, option?: Intl.DateTimeFormatOptions) {
    return date.toLocaleDateString('pt-bt', option);
  }

  function formatTime(date: Date, option?: Intl.DateTimeFormatOptions) {
    return date.toLocaleTimeString('pt-bt', option);
  }

  return (
    <Container>
      {lote.bilhetes.map((bilhete) => (
        <>
          <p>
            POS: {userInfo.pos.id} - {userInfo.mac_address?.slice(12)} {version}
          </p>
          <p>VENDEDOR: {userInfo.usuario.nome || ''}</p>
          <p>ROTA: {getPreServer()}</p>
          <p>VALOR PULE: {lote.valor_doado}</p>
          <p>QTD DE BILHETES: {lote.bilhetes.length}</p>
          <p>PULE: ${lote.codigo}</p>
          <p>EMISSÃO: ${formatTime(new Date())}</p>
          <hr />
          <p>
            SORTEIO: *{bilhete.sorteio}* SORTEIO {bilhete.tipo_rodada}
          </p>
          <p>DATA PARTIDA: {formatDate(new Date(bilhete.data_partida))}</p>
          <p>BILHETE: {bilhete.bilhete}</p>
          <p>QTD DE CARTELAS: {bilhete.cartelas.length}</p>
          <p>VALOR CARTELA: {bilhete.valor_cartela}</p>
          <p>
            VALOR BILHETE:{' '}
            {(bilhete.valor_cartela * bilhete.cartelas.length).toFixed(2)}
          </p>
          <hr />

          {bilhete.tipo_impressao == 'L' ||
            (bilhete.tipo_impressao == 'I' && (
              <>
                <Carts carts={bilhete.cartelas} type={bilhete.tipo_impressao} />
                <hr />
              </>
            ))}
          <p>
            *SEQUÊNCIA DE:*{bilhete.cartelas[0]?.codigo} a{' '}
            {bilhete.cartelas?.[bilhete.cartelas?.length - 1]?.codigo}*
          </p>
          {lote.bilhetes_invalidos.length > 0 && (
            <>
              <hr />
              <p>Bilhetes do(s) Sorteio(s):</p>
              <p>
                {lote.bilhetes_invalidos.map((bilhete, index) => (
                  <>
                    {index == 0 ? (
                      <>{bilhete.partida}</>
                    ) : (
                      <> - {bilhete.partida}</>
                    )}
                  </>
                ))}
              </p>
              <p>Não foram efetivados devido à apostas encerradas.</p>
              <hr />
            </>
          )}
          <p>Acesse o Link para acompanhar o jogo</p>
          <p>
            https://{userInfo?.url_qrcode}/?pule={lote.codigo}`
          </p>
        </>
      ))}
    </Container>
  );
};

export default PrintPreSale;
