import React from 'react';

import { Container, Wrapper, Item, Numbers, Row } from './styles';

interface CartsProps {
    carts: Cartela[];
    type: string;
}

const Carts: React.FC<CartsProps> = ({ carts, type }) => {
    
    return (
        <Container>
            <Wrapper type={type}>
                {carts.map((cart, index) => (

                    <Item key={index}>
                        <h3>{cart.codigo}</h3>
                        <Numbers>
                            <Row>
                                {cart.linha1_lista.map((number, i) => (
                                    <>
                                        {i == 0 ? number.toString().padStart(2, '0') : ` | ${number.toString().padStart(2)} `}
                                    </>

                                ))}
                            </Row>
                            <Row>
                                {cart.linha2_lista.map((number, i) => (
                                    <>
                                        {i == 0 ? number.toString().padStart(2, '0') : ` | ${number.toString().padStart(2)} `}
                                    </>

                                ))}
                            </Row>
                            <Row>
                                {cart.linha3_lista.map((number, i) => (
                                    <>
                                        {i == 0 ? number.toString().padStart(2, '0') : ` | ${number.toString().padStart(2)} `}
                                    </>

                                ))}
                            </Row>

                        </Numbers>
                    </Item>

                ))}
            </Wrapper>
        </Container>
    );
}

export default Carts;