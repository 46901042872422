import React, { ReactNode } from 'react';
import { ThemeProvider } from 'styled-components';


// import { ServicesProvider } from './Services';
import { AlertProvider } from './alert';
import { AuthProvider } from './auth';
import { GlobalStyles } from '../Pages/Styles/global';
import { SideBarProvider } from './expandSideBar';
import defaultTheme from '../Pages/Styles/theme';


interface AppProviderProps {
  children: ReactNode;
}

export function AppProvider({ children }: AppProviderProps) {
  return (
    <ThemeProvider theme={defaultTheme}>
      <GlobalStyles />
      <AlertProvider>        
          <AuthProvider>
            <SideBarProvider>{children}</SideBarProvider>
          </AuthProvider>       
      </AlertProvider>
    </ThemeProvider>
  );
}
