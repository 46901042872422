import styled from 'styled-components';

export const Container = styled.header`
  /* position: fixed; */
  top: 0;
  width: 100%;
  background-color: ${({ theme }) => theme.colors.shape};
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0.5rem 1rem;
  overflow: hidden;

  & > span {
    display: flex;
    flex: 1;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }

  & > button {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 0.5rem;
    font-size: 1.5rem;
    cursor: pointer;
  }
`;
