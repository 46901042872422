import React from 'react';
import genId from '../../utils/genID';

import { Container } from './styles';

export interface TableProps<T> {
  columns: {
    [key in keyof T]: string;
  };
  data: Array<T>;
}

function Table2<T>({ columns, data }: TableProps<T>) {
  const columns_keys = Object.keys(columns);
  console.log(genId());
  return (
    <Container>
      <thead>
        <tr>
          {columns_keys.map((column) => (
            <th key={column}>{columns[column as keyof T]}</th>
          ))}
        </tr>
      </thead>

      <tbody>
        {data.map((row) => (
          <tr key={genId()}>
            {columns_keys.map((cell) => (
              <td key={genId()}>{row[cell as keyof T]}</td>
            ))}
          </tr>
        ))}
      </tbody>
    </Container>
  );
}

export default Table2;
