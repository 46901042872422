import React, { useState } from 'react';
import { FaSearch } from 'react-icons/fa';
import { getHistory } from '../../Services/resquestsV5';
import { format, isAfter, isValid, parseISO } from 'date-fns';
import { Container, Title, Filter, InputGroup } from './styles';
import Table from '../../Components/Table';
import { formatMoneyDec } from '../../utils/formatMoney';
import TotalTable from '../../Components/Table/TotalTable';
import { toast } from 'react-toastify';


interface IPayload {
    start: string;
    end: string;
}

const HistoricoSorteio: React.FC = () => {
    const initialInfoState = {
        start: "",
        end: "",
    };
    const [history, setHistory] = useState<IHistorico>();
    const [payload, setPayload] = useState<IPayload>(initialInfoState);


    const handleInputChange = (event: any) => {
        const { name, value } = event.target;
        setPayload({ ...payload, [name]: value });
    };

    const handleSubmit = async () => {
        if (payload.start == '' || payload.end == '') {
            toast.error('Necessário o preenchimento das datas.');
            return;
        }
        if (isAfter(parseISO(payload.start), parseISO(payload.end))) {
            toast.error('A data de inicial tem que ser antes da data final.');
            return;
        }
        if (isAfter(parseISO(payload.start), new Date()) ||
            isAfter(parseISO(payload.end), new Date())
        ) {
            toast.error('As datas não podem ser futuras.');
            return;
        }

        const start = format(parseISO(payload.start), 'dd/MM/yyyy');
        
        const end = format(parseISO(payload.end), 'dd/MM/yyyy');
        const response = await getHistory(start, end);
        if (response?.movimento.length == 0) {
            toast.error('Não existe movimentações nesse período.');
        }
        setHistory(response);
        
    }

    return (
        <Container>
            <Title>Histórico por Sorteio:</Title>
            <Filter>
                <InputGroup>
                    <span>Data Inicial:</span>
                    <input type="date"
                        name="start"
                        value={payload.start}
                        onChange={handleInputChange} />
                </InputGroup>
                <InputGroup>
                    <span>Data Final:</span>
                    <input type="date"
                        name="end"
                        value={payload.end}
                        onChange={handleInputChange} />
                </InputGroup>
                <button onClick={() => handleSubmit()}><FaSearch /></button>
            </Filter>

            {history?.movimento && history?.movimento.length > 0 && (
                <>
                    <Table columnsData={{
                        'Sorteio': history?.movimento.map((item) => item['sorteio']),
                        'Doações': history?.movimento.map((item) => (formatMoneyDec(item['doacoes']))),
                        'Prêmios': history?.movimento.map((item) => (formatMoneyDec(item['premios_pagos']))),
                        'Comissões': history?.movimento.map((item) => (formatMoneyDec(item['comissao']))),
                        'Saldo': history?.movimento.map((item) => (formatMoneyDec(item['saldo']))),
                    }} />

                    <TotalTable values={
                        [
                            "Total:",
                            formatMoneyDec(history.totalDoacoes),
                            formatMoneyDec(history.totalPago),
                            formatMoneyDec(history.totalComissao),
                            formatMoneyDec(history.totalSaldo),
                        ]
                    } />
                </>
            )}

        </Container>
    );
}

export default HistoricoSorteio;