import React, { useEffect, useState } from 'react';

import { ColoredText, Container, Line, Separator } from './styles';

interface DonateTableProps {
  sorteio?: SorteioData;
}

function TimerCountDow(props: { date?: Date; days?: boolean }) {
  const { date, days } = props;
  const [dif, setDif] = useState(date && date.getTime() - new Date().getTime());
  const seg = dif && Math.round(dif / 1000);
  const min = seg && Math.round(seg / 60);
  const hor = min && Math.round(min / 60);
  const dias = hor && Math.round(hor / 24);

  useEffect(() => {
    if (date) {
      setDif(date.getTime() - new Date().getTime());
    }
  }, [date]);

  useEffect(() => {
    if (date && Number(dif) > 0) {
      const timeout = setTimeout(
        () => setDif(() => date.getTime() - new Date().getTime()),
        1000
      );

      return () => {
        clearTimeout(timeout);
      };
    }
  }, [dif]);

  return Number(dif) > 0 ? (
    <>
      {days && `${dias ?? '--'} dias`}{' '}
      {(hor && (days ? hor % 24 : hor)) ?? '--'}:{(min && min % 60) ?? '--'}:
      {(seg && seg % 60) ?? '--'}
    </>
  ) : (
    <>...</>
  );
}

export function DonateTable({ sorteio }: DonateTableProps) {
  const sorteioDate = sorteio && new Date(sorteio.data_partida);
  const antecipadoDate = sorteio && new Date(sorteio.hora_antecipado);

  return (
    <Container>
      <h4>
        <span>Sorteio começa em: </span>
        <strong>
          <TimerCountDow date={sorteioDate} days />
        </strong>
      </h4>

      <Line>
        <span>Sorteio: {sorteio?.codigo || '----'}</span>
        <strong>{sorteio?.tipo_rodada?.split(' ')[0] ?? '?????'}</strong>
        <span>
          {sorteioDate?.toLocaleDateString('pt-br', {
            day: '2-digit',
            month: '2-digit',
          }) ?? '--'}{' '}
          -{' '}
          {sorteioDate?.toLocaleTimeString('pt-br', {
            hour: '2-digit',
            minute: '2-digit',
          }) ?? '--'}
        </span>
      </Line>
      <Separator orientation="horizontal" />
      <Line>
        <small>KUADRA</small>
        <small>KINA</small>
        <small>KENO</small>
      </Line>
      <Separator orientation="horizontal" />
      <Line>
        <p>
          {' '}
          {sorteio?.valor_kuadra?.toLocaleString('pt-br', {
            minimumFractionDigits: 2,
          }) ?? '--'}
        </p>
        <p>
          {' '}
          {sorteio?.valor_kina?.toLocaleString('pt-br', {
            minimumFractionDigits: 2,
          }) ?? '--'}
        </p>
        <p>
          {' '}
          {sorteio?.valor_keno?.toLocaleString('pt-br', {
            minimumFractionDigits: 2,
          }) ?? '--'}
        </p>
      </Line>
      <Separator orientation="horizontal" />
      <Line>
        <ColoredText color="sucess">ANTECIPADA</ColoredText>
        <ColoredText color="sucess">TEMPO</ColoredText>
        <ColoredText color="#222">NORMAL</ColoredText>
      </Line>
      <Separator orientation="horizontal" />
      <Line>
        <ColoredText color="sucess">
          {' '}
          {sorteio?.valor_antecipado?.toLocaleString('pt-br', {
            minimumFractionDigits: 2,
          }) ?? '--'}
        </ColoredText>
        <ColoredText color="#666">
          <TimerCountDow date={antecipadoDate} />
        </ColoredText>
        <ColoredText color="#222">
          {' '}
          {sorteio?.valor_dia?.toLocaleString('pt-br', {
            minimumFractionDigits: 2,
          }) ?? '--'}
        </ColoredText>
      </Line>
      <Separator orientation="horizontal" />
    </Container>
  );
}

export default DonateTable;
