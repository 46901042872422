import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: stretch;
  justify-content: flex-start;
  flex: 1;
  height: 100vh;
  width: 100vw;
  background-color: ${({ theme }) => theme.colors.secondary};
  overflow: hidden;
  overflow-y: auto;
  `


export const OutletContainer = styled.main`
  display: flex;
  flex: 1;
  flex-direction: column;
  align-items: stretch;
  max-height: calc(100vh - 4rem);
  min-height: fit-content;
  
`
