import React from "react";
import * as Style from "./style";
import LogoPDV from "../../img/logo.png"

export const Loadding = () =>{
    return(
        <Style.Container>
            <Style.Container>
                <Style.Imagem src={LogoPDV}/>
                <Style.Title>Carregando informações....</Style.Title>
            </Style.Container>
        </Style.Container>
    )
}