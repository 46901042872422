import React from 'react';
import { Route, Routes } from 'react-router-dom';
import Layout from '../Components/Layout';

import Doacao from '../Pages/Doacao';
import Login from '../Pages/Login';
import Payment from '../Pages/Payment';
import PreCompra from '../Pages/PreCompra';
import Recolhe from '../Pages/Recolhe';
import Relatorios from '../Pages/Relatorios';
import HistoricoSorteio from '../Pages/HistoricoSorteio';
import {PrivateRoute, LoginRoute} from './Routes';

export function AppRouter() {
  
  return (
    <div>
      <Routes>
        <Route path="/login" element={<LoginRoute component={Login} />}  />
        <Route path="/" element={<Layout />}>
          <Route index element={<PrivateRoute component={Doacao} />} />
          <Route path="/pre-compra" element={<PrivateRoute component={PreCompra} />} />
          <Route path="/recolhe" element={<PrivateRoute component={Recolhe} />} />
          <Route path='/relatorios' element={<PrivateRoute component={Relatorios} />}/>
          <Route path="/pagar-premio" element={<PrivateRoute component={Payment} />} />
          <Route path="/historico-sorteio" element={<PrivateRoute component={HistoricoSorteio} />} />
        </Route>
      </Routes>
    </div>
  );
}
