import React from 'react';

import { Container } from './styles';

interface TableProps {
  columnsData: {
    [key: string]: string[] | number[];
  };
}

function handleInvertTableMatrix(tableData: TableProps['columnsData']) {
  const columns = Object.keys(tableData);
  const rows: string[][] | number[][] = [];

  columns.forEach((column, col) => {
    tableData[column].forEach((cell, row) => {
      if (!rows[row]) {
        rows.push([]);
      }
      rows[row][col] = cell;
    });
  });

  return rows;
}

function Table({ columnsData }: TableProps) {
  const columns = Object.keys(columnsData);
  const matrix = handleInvertTableMatrix(columnsData);
  
  return (
    <Container>
      <thead>
        <tr>
          {columns.map((column) => (
            <th key={column}>{column}</th>
          ))}
        </tr>
      </thead>
      <tbody>

        {matrix.map((row, index) => (
          <tr key={index}>
            {row?.map((cell, indexRow) => <td key={cell +'@' +indexRow}>{cell}</td>) || <></>}

          </tr>
        ))}
      </tbody>
    </Container>
  );
}

export default Table;
