import { format } from 'date-fns';
import React, { useEffect, useState } from 'react';
import { IoPrint } from 'react-icons/io5';
import Button from '../../Components/Button';
import Input from '../../Components/Form/Input';
import Table from '../../Components/Table';
import TotalTable from '../../Components/Table/TotalTable';
import { useAuth } from '../../Context/auth';
import { recolhimentoPost, recebimentosGet } from '../../Services/resquestsV5';
import { transformDate } from '../../utils/formatData';
import { formatMoneyDec } from '../../utils/formatMoney';
import * as Style from './RecolheStyle';
import { useAsyncFn } from 'react-use';
import { Skeleton, useInterval } from '@chakra-ui/react';
import { Loadding } from '../../Components/Loadding';
import { toast } from 'react-toastify';
import { Modal } from '../../Components/Modal';

export default function Recolhe() {
  const { user } = useAuth();
  const [ValueRecolhe, setValueRecolhe] = useState<any>();
  const [recebimentos, setRecebimentos] = useState<IRecebimentos | undefined>();
  const [VisibleModal, setVisibleModal] = useState(false);
  const [Senha, setSenha] = useState('');
  const [Reload, setReload] = useState(false);

  const [state, reload] = useAsyncFn(async () => {
    console.log('loading...');
    const { data, error } = await recebimentosGet(user?.pos?.id || 0);
    if (data) {
      console.log(data)
      const databoa = data.datas[data.datas.length -1].data.replace(/-/g, '/'); 
      console.log(databoa)
      console.log(format(new Date(databoa), 'dd/MM/yyyy'))
      return data;
    } else toast.error(error);
  });

  const handleRecolhe = async (transaction: 'T' | 'A') => {
    const { data, error } = await recolhimentoPost(
      ValueRecolhe,
      transaction,
      Senha
    );
    if (data) toast.success(data);
    else toast.error(error);
  };

  useEffect(() => {
    if (state.value) {
      setRecebimentos(state.value);
    }
  }, [state]);

  useInterval(() => setReload(true), 30000);

  useEffect(() => {
    reload();
  }, []);

  if (!state.loading) {
    return (
      <>
        {VisibleModal && (
          <Modal
            modalVisible={() => {
              setVisibleModal(false);
            }}
            title="Digite sua senha"
          >
            <Input
              onChange={(e) => {
                setSenha(e.target.value);
              }}
              type="password"
            />
            <Style.ContainerButton>
              {recebimentos && recebimentos?.saldoLiquido >= 0 ? (
                <Style.ButtonModal
                  style={{ margin: '0' }}
                  onClick={() => {
                    handleRecolhe('T');
                    setVisibleModal(false);
                    setSenha('');
                  }}
                >
                  confirmar
                </Style.ButtonModal>
              ) : (
                <Style.ButtonModal
                  onClick={() => {
                    handleRecolhe('A');
                  }}
                >
                  confirmar
                </Style.ButtonModal>
              )}
            </Style.ContainerButton>
          </Modal>
        )}
        <Style.Container>
          <Style.Title>Recebimento/Recolhe:</Style.Title>
          <Style.CurrentCash>
            Saldo Atual:{' '}
            {recebimentos && formatMoneyDec(recebimentos?.saldoLiquido)}
          </Style.CurrentCash>
          <Style.ValueSection>
            <Style.ContainerForm>
              <Style.InputRecolhe
                type="number"
                onChange={(e: any) => {
                  const inputValue = e.target.value.toString();
                  console.log(inputValue);
                  if (inputValue !== '') {
                    console.log(inputValue);
                    const regex1 = /[0-9.]/g;
                    const val = regex1.test(
                      inputValue.slice(inputValue.length - 1)
                    );

                    console.log(val);
                    if (val == true) {
                      setValueRecolhe(e.target.value);
                    }
                  } else {
                    setValueRecolhe(e.target.value);
                  }
                }}
                value={ValueRecolhe}
                placeholder="R$"
              />

              {Reload ? (
                <Style.ButtonRecolhe
                  className='BtnUpdate'
                  onClick={() => {
                    reload();
                    setReload(false);
                  }}
                >
                  Atualizar
                </Style.ButtonRecolhe>
              ) : (
                <Style.ButtonRecolhe
                  onClick={() => {
                    // {
                    //   Recebimentos && user && Recebimentos?.saldoLiquido === 0
                    //     ? reload()
                    setVisibleModal(true);
                    // }
                  }}
                >
                  {recebimentos && user && recebimentos?.saldoLiquido >= 0
                    ? 'Solicitar Recolhe'
                    : 'Solicitar Aporte'}
                </Style.ButtonRecolhe>
              )}
            </Style.ContainerForm>
            <Style.ChashHistoryResume>
              <span>
                Saldo anterior:{' '}
                {recebimentos && formatMoneyDec(recebimentos?.saldoLiquido)}
              </span>
              <span>
                Ultimo recolhe:{' '}
                {state.loading ? (
                  <Skeleton isLoaded />
                ) : recebimentos && recebimentos.ultimoRecolhe ? (
                  format(
                    transformDate(recebimentos?.ultimoRecolhe),
                    'dd/MM/yyyy HH:mm:ss'
                  )
                ) : (
                  '--'
                )}
              </span>
              <span>
                Ultima atualização:{' '}
                {state.loading ? (
                  <Skeleton isLoaded />
                ) : recebimentos && recebimentos.datahora ? (
                  format(
                    transformDate(recebimentos.datahora),
                    'dd/MM/yyyy HH:mm:ss'
                  )
                ) : (
                  '--'
                )}
              </span>
            </Style.ChashHistoryResume>

            {recebimentos?.datas && (
              <>
                <Table
                  columnsData={{
                    Dia: recebimentos?.datas.map((item) =>
                      format(new Date(item['data'].replace(/-/g, '/')), 'dd/MM/yyyy')
                    ),
                    Doações: recebimentos?.datas.map((item) =>
                      formatMoneyDec(item['doacoes'])
                    ),
                    Prêmios: recebimentos?.datas.map((item) =>
                      formatMoneyDec(item['premios'])
                    ),
                    Comissões: recebimentos?.datas.map((item) =>
                      formatMoneyDec(item['comissao'])
                    ),
                    Saldo: recebimentos?.datas.map((item) =>
                      formatMoneyDec(item['saldo'])
                    ),
                  }}
                />

                <TotalTable
                  values={[
                    'Total:',
                    formatMoneyDec(recebimentos.totalDoacoes),
                    formatMoneyDec(recebimentos.totalPremios),
                    formatMoneyDec(recebimentos.totalComissao),
                    formatMoneyDec(recebimentos.totalSaldo),
                  ]}
                />
              </>
            )}
          </Style.ValueSection>
        </Style.Container>
      </>
    );
  } else {
    return <Loadding />;
  }
}
