import React, { useState, useRef } from 'react';
import { DialogClose } from '@radix-ui/react-dialog';
import { useForm } from 'react-hook-form';
import { IoSendSharp } from 'react-icons/io5';
import Button from './Button';
import DonateTable from './DonateTable';
import InputNumber from './Form/InputNumber';
import { DialogContainer, LoginServerDialog } from './LoginServerDialog';
import { Donate, DonateForm } from '../Pages/Doacao/DoacaoStyle';
import Input from './Form/Input';
import { requestDonate } from '../Services/resquestsV5';
import { compartilharMsg, compartilharMsgSemLink } from '../utils/compartilhar';
import { useAuth } from '../Context/auth';
import InputMask from 'react-input-mask';
import { toast } from 'react-toastify';
import { useReactToPrint } from 'react-to-print';
import PrintDonate from './Print/PrintDonate';

interface DonateSectionProps {
  codigo?: string;
  sorteio?: SorteioData;
  onConfirm: () => void;
  onOpen: (data: { value: string }) => void;
}

const InputMaskTel = (props: any) => {
  return (
    <InputMask
      mask="(99) 99999-9999"
      value={props.value}
      onChange={props.onChange}
      type="tel"
      placeholder={'(99) 99999-9999'}
    >
      {(inputProps: any) => <Input {...inputProps} />}
    </InputMask>
  );
};

function DonateSection({
  onOpen,
  sorteio,
  onConfirm,
  codigo,
}: DonateSectionProps) {
  const [numCartelas, setNumCartelas] = useState(0);
  const [phone, setPhone] = useState('');
  const { register, handleSubmit } = useForm();
  const { user, deviceInfo } = useAuth();
  const [bilheteResponse, setBilheteResponse] = useState<IBilhete>();
  const [userDeviceData, setUserDeviceData] = useState<IUser & DeviceInfo>();
  const [message, setMessage] = useState('');

  const componentRef = useRef<HTMLDivElement>(null);

  function handleOpen(value: string) {
    setNumCartelas(Number(value));
    onOpen({ value });
  }

  function clickDonate(e: any) {
    handleOpen(e.currentTarget.innerText);
    phone.replace('(', '').replace(')', '').replace('_', '').replace(' ', '').replace('-', '')
  }

  async function handleDonate() {
    try {
      if (codigo) {
        const { data } = await requestDonate(codigo, numCartelas);
        const userData = {
          ...(user as IUser),
          ...(deviceInfo as DeviceInfo),
        };
        if (data) {
          setBilheteResponse(data.data);
          setUserDeviceData(userData);
          
          // Se o compartilhamento da URL estiver ativa, entrará no código abaixo, enviando a URL para acessar no jogo na mensagem 👇🏼👻
          if (data.data.url_qrcode != null || data.data.url_qrcode != undefined) {
            const message = compartilharMsg(
              data.data,
              userData,
              process.env.VERSION || 'beta'
            );
            setMessage(message);
            const url = `https://api.whatsapp.com/send?phone=55${phone}&text=${encodeURI(message)}`;
          
            // O uso do setTimeout foi realizado para corrigir um problema do sistema IOS
            setTimeout(() => { 
              window.open(url, '_blank')
            })
            onConfirm();
          }

          // Se o compartilhamento estiver desativado, entrará no código abaixo, não enviando a URL para acessar no jogo na mensagem 👇🏼👻
          else{
            const message = compartilharMsgSemLink(
              data.data,
              userData,
              process.env.VERSION || 'beta'
            );
            setMessage(message);
            const url = `https://api.whatsapp.com/send?phone=55${phone}&text=${encodeURI(message)}`;
          
            // O uso do setTimeout foi realizado para corrigir um problema do sistema IOS
            setTimeout(() => { 
              window.open(url, '_blank')
            })
            onConfirm();
          } 

        }
      }
    } catch (err) {
      toast.error('não foi possivel finalizar a doação!');
    } finally {
      setPhone('');
    }
  }

  const handlePrint = useReactToPrint({
    content: () => componentRef.current,
  });

  return (
    <DialogContainer>
      <DonateForm onSubmit={handleSubmit((data) => handleOpen(data.value))}>
        <section>
          <InputNumber {...register('value')} />
          <Donate disabled={!codigo} type="submit">
            <IoSendSharp />
          </Donate>
        </section>
        <section>
          <Donate disabled={!codigo} onClick={clickDonate}>
            5
          </Donate>
          <Donate disabled={!codigo} onClick={clickDonate}>
            10
          </Donate>
          <Donate disabled={!codigo} onClick={clickDonate}>
            20
          </Donate>
          <Donate disabled={!codigo} onClick={clickDonate}>
            30
          </Donate>
          <Donate disabled={!codigo} onClick={clickDonate}>
            40
          </Donate>
          <Donate disabled={!codigo} onClick={clickDonate}>
            50
          </Donate>
        </section>
      </DonateForm>
      <LoginServerDialog title="">
        <h1>Confirmar Doação:</h1>
        <DonateTable sorteio={sorteio} />
        <small>Total de cartelas: {numCartelas}</small>
        <br />
        <h2>
          Valor da doação: R${' '}
          {(
            (sorteio?.valor_dia ?? sorteio?.valor_antecipado ?? 0) *
              numCartelas || undefined
          )?.toLocaleString('pt-br', {
            minimumFractionDigits: 2,
          }) ?? '--'}
        </h2>
        <section className="send">
          <InputMaskTel
            value={phone}
            type="tel"
            onChange={(e: any) => {
              e.preventDefault();
              setPhone(e.target.value.replace('(', '').replace(')', '').replace('_', '').replace(' ', '').replace('-', ''));
            }}
          />
          <DialogClose asChild>
            <Button
              disabled={
                phone.replace('(', '').replace(')', '').replace('_', '').replace(' ', '').replace('-', '').length < 11
              }
              onClick={() => handleDonate()}
            >
              Enviar
            </Button>
          </DialogClose>
        </section>
      </LoginServerDialog>
      {userDeviceData && bilheteResponse && (
        <>
          <Button onClick={handlePrint} style={{ marginTop: '10px' }}>
            Imprimir
          </Button>
          <Button
            onClick={() => {
              navigator.clipboard.writeText(message);
            }}
            style={{ marginTop: '10px' }}
          >
            Copiar Impressão
          </Button>
          <div>
            <div ref={componentRef}>
              <PrintDonate
                bilhete={bilheteResponse}
                userInfo={userDeviceData}
                version={process.env.VERSION || 'beta'}
              />
            </div>
          </div>
        </>
      )}
    </DialogContainer>
  );
}

export default DonateSection;
