import React, { ReactNode } from 'react';
import { IoClose } from 'react-icons/io5';
import Button from '../Button';
import * as Style from './styleModal';

interface ModalProps extends React.HTMLAttributes<HTMLDivElement> {
  children: ReactNode;
  title: string;
  modalVisible?: () => void;
}
export const Modal = ({ children, title, modalVisible }: ModalProps) => {
  return (
    <Style.Container>
      <Style.Content>
        <Style.ContentTop>
          <Style.Title>{title}</Style.Title>
          <Style.ButtonClose
            onClick={() => {
              modalVisible && modalVisible();
            }}
          >
            <IoClose/>
          </Style.ButtonClose>
        </Style.ContentTop>

        {children}
      </Style.Content>
    </Style.Container>
  );
};
