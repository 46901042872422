import styled from 'styled-components';

export const Container = styled.select`
  font-size: 1.2rem;
  color: ${({ theme }) => theme.text.input};
  border-radius: 0.2rem;
  min-height: 45px;
  outline: none;
  border: 1px solid ${({ theme }) => theme.colors.secondary};
  background: ${({ theme }) => theme.colors.deep};
  transition: 0.2s;

  margin-top: 1rem;
  :focus {
    border-color: ${({ theme }) => theme.colors.primary};
    border-width: 2px;
  }
`;
