import styled from 'styled-components';
import Button from '../../Components/Button';

export const Container = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  background: #e4f8ff;
  min-width: 100vw;
  min-height: 100vh;
  padding: 0 1.25rem;
`;
export const LogoImage = styled.img`
  height: 10rem;
`;
export const LoginForm = styled.form`
  display: flex;
  flex-direction: column;
  width: 100%;
  background: white;
  padding: 0 2rem;
  margin: 1rem 0;
  border-radius: 0.32rem;
  min-height: 270px;
  max-width: 420px;

  box-shadow: ${({ theme }) => theme.shadow.equipament};
`;

export const BtnLogin = styled(Button)`
  margin-top: 1.5rem;
`;

export const Descripition = styled.p`
  padding-top: 4rem;

  font-size: 1.1rem;
  font-weight: bold;
`;

export const FeedBackUser = styled.p`
  padding: 1rem;
  height: 2.6rem;
  font-size: 1.1rem;
  color: #d73333;

  font-weight: bold;
`;
