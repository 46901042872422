import { toast } from 'react-toastify';
import logout from './logout';


const handleError = (status?: number, details?: string, detail?: string) => {
 
    if (status == 401) {
        alert("Erro 401: Sessão expirada");
        logout();
    }
    if (status == 403) {
        alert("Erro 403: Sessão expirada");
        logout();
    }
    if (details) {
        toast.error(details);
    } else if(detail) {
        toast.error(detail);
    } else{
        toast.error('Erro Interno. Tente novamente mais tarde.');
    }
}

export default handleError;