import React, { useEffect } from 'react';

import { Container } from './RelatoriosStyle';

export default function Relatorios() {
  useEffect(() => {
    window.open(
      `https://${process.env.REACT_APP_SERVER}.${process.env.REACT_APP_DOMAIN}/login`,
      '_blank'
    );
  }, []);

  return (
    <>
      <Container>
        <div 
        style={{cursor: 'pointer'}}
          onClick={() =>
            window.open(
              `https://${process.env.REACT_APP_SERVER}.${process.env.REACT_APP_DOMAIN}/login`,
              '_blank'
            )
          }
        >
          Clique para abrir a página de relatório
        </div>
      </Container>
    </>
  );
}
