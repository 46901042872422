import React, { useContext } from 'react';
import { Outlet } from 'react-router-dom';
import { SideBarContext } from '../../Context/expandSideBar';
import Header from '../Header';

import { Container, OutletContainer } from './styles';

function Layout() {
  const { setexpandSideBar } = useContext(SideBarContext);
  return (
    <Container>
      <Header />
      <OutletContainer onClickCapture={() => setexpandSideBar(false)}>
        <Outlet />
      </OutletContainer>
    </Container>
  );
}

export default Layout;
