/* global IUser */
// import AsyncStorage from '@react-native-async-storage/async-storage';

// import { useInterval } from '@chakra-ui/react';
import React, { useCallback, useContext, useEffect, useState } from 'react';
import { useLocalStorage } from 'react-use';
import { setToken, v5 } from '../Services/Api';

// import { useNavigate } from 'react-router-dom';
//import { v2, setToken, SetOnInvalidSession } from '../../Services/Api';
//import { version } from '../../../package.json';

interface IAuthContext {
  user?: IUser;
  signed: boolean;
  loading: boolean;
  signIn: (
    email: string,
    password: string,
    mac: string
  ) => Promise<IAccountData | undefined>;
  // signOut: () => void;
  deviceInfo?: DeviceInfo;
}

const AuthContext = React.createContext<IAuthContext>({
  user: undefined,
  signed: false,
} as IAuthContext);

export const AuthProvider: React.FC = ({ children }) => {
  //const [userData, setUserData] = useState<IUser | undefined>(undefined);
  const [userData, setUserData] = useLocalStorage<IUser | undefined>(
    '@GDSK:user'
  );
  const [deviceInfo, setDeviceInfo] = useState<DeviceInfo>();
  const [loading, setLoading] = useState(false);

  const handleSetUser = useCallback(
    (user?: IUser) => {
      setUserData(user);
    },
    [setUserData]
  );
  // const navigate = useNavigate();

  // useInterval(async () => {
  // if (!userData || userData.nome_server === 'pradelivery') return;
  // try {
  //   await v2.get('/ping_pos/');
  // } catch (err) {
  //   //ignore
  // }
  // }, 30000);

  // useEffect(() => {
  //   async function fetchDeviceInfo() {
  //     //   const android_id = await getAndroidId();
  //     //let mac_address = (await AsyncStorage.getItem('@GDSK:mac')) || (await getMacAddress());
  //     //let access_code = mac_address;
  //     //   if (mac_address.includes('00:00:00')) {
  //     //     mac_address = 'MAC Inválido, reinicie o aparelho!';
  //     //     access_code = process.env.NODE_ENV === 'development' ? 'dev' : 'dispositivo_invalido';
  //     //   } else {
  //     //     //console.log('saving mac');
  //     //     await AsyncStorage.setItem('@GDSK:mac', mac_address);
  //     //   }
  //   }

  //   fetchDeviceInfo();
  // }, [handleSetUser]);

  const signIn = useCallback(
    async (usuario: string, senha: string, mac: string) => {
      if (loading) return;

      const payload = {
        mac_address: mac,
        usuario: usuario,
        senha: senha,
      };

      setLoading(true);
      try {
        const response = await v5.post('/login/', payload, { timeout: 30000 });
        if (!response) {
          setLoading(false);
          return undefined;
        }
        const { data } = response;

        if (data.details) alert(data.details);
        setUserData(data);
        setToken(data.token, data.session_id);

        // handleSetUser({ ...(data || {}), logado: !!data });
        setUserData(data);
        // await AsyncStorage.setItem('@GDSK:user', payload.usuario);
        // navigate('/doacao');

        location.replace('/');
        setLoading(false);
        return data.usuario;
      } catch (err: any) {
        const { data } = err?.response || {};

        if (data?.details) alert(data.details);
        else alert('Erro Interno de login');
      }

      setLoading(false);
      return undefined;
    },
    [deviceInfo, loading]
  );

  useEffect(() => {
    if (userData) {
      console.log('encontrado usuário');
    } else {
      console.log('não encontrado usuário');
    }
  }, [userData]);

  const value: IAuthContext = {
    user: userData,
    signed: !!userData,
    deviceInfo,
    loading,
    signIn,
  };

  return <AuthContext.Provider value={value}>{children}</AuthContext.Provider>;
};

export const useAuth = () => {
  const context = useContext(AuthContext);
  return context;
};
