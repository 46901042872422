import React, { ReactNode } from 'react';
import { DialogTitle, Portal } from '@radix-ui/react-dialog';
import {
  DialogOverlay,
  DialogContent,
  DialogTrigger,
  DialogDescription,
  DialogClose,
  Dialog,
  CloseButton,
} from './styles';
import { IoClose } from 'react-icons/io5';

interface LoginServerDialogProps {
  description?: string;
  title: string;
  children?: ReactNode;
}

function LoginServerDialog({
  title,
  children,
  description,
}: LoginServerDialogProps) {
  return (
    <Portal>
      <DialogOverlay />
      <DialogContent>
        <DialogTitle>{title}</DialogTitle>
        {description && <DialogDescription>{description}</DialogDescription>}
        {children}
        <DialogClose asChild>
          <CloseButton>
            <IoClose />
          </CloseButton>
        </DialogClose>
      </DialogContent>
    </Portal>
  );
}
const DialogContainer = Dialog;
export { DialogTrigger, DialogContainer, LoginServerDialog };
