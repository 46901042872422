import React, { useEffect, useState } from 'react';
import { TabsContentProps } from '@radix-ui/react-tabs';
import { Container } from './styles';
import DonateTable from '../DonateTable';
import DonateSection from '../DonateSection';
import { SelectInput } from '../Form/select-input';
import { useAsyncFn, useEffectOnce } from 'react-use';
import { toast } from 'react-toastify';
import { requestGetNextGames } from '../../Services/resquestsV5';

function handleGenSorteioDescription(sorteio: SorteioData) {
  const data = new Date(sorteio.data_partida);
  return `${sorteio.tipo_rodada?.split(' ')[0]} #${
    sorteio.codigo
  } - ${data.toLocaleDateString('pt-br')} - ${data.toLocaleTimeString('pt-br', {
    hour: '2-digit',
    minute: '2-digit',
  })} - ${sorteio.valor_keno}`;
}

function DonateTab(props: TabsContentProps) {
  const [{ loading, error, value }, reload] = useAsyncFn(async () => {
    const { data } = await requestGetNextGames();
    console.log(data);
    if (data) {
      return data.data;
    }
  });
  const [filtro, setFiltro] = useState('');
  const [selectedSorteio, setSelectedSorteio] = useState<SorteioData>();

  useEffectOnce(() => {
    reload();
  });

  useEffect(() => {
    if (!loading && error) {
      toast.error('Não conseguimos carregar todas as partidas...');

      console.error(error);
      setSelectedSorteio(undefined);
    } else if (value) {
      setSelectedSorteio(value[0]);
    }
  }, [loading, error, value]);

  return (
    <Container {...props}>
      {/* <Input
        autoComplete="off"
        type="number"
        placeholder="Digite o código do seu sorteio"
        value={filtro}
        onChange={(e) => {
          e.preventDefault();
          setFiltro(e.target.value.replace(/\D+/g, ''));
        }}
      /> */}
      <SelectInput
        onMouseEnter={reload}
        value={JSON.stringify(selectedSorteio)}
        onChange={(e) => {
          const { value } = e.target;
          const sorteio = JSON.parse(value);
          setSelectedSorteio(sorteio);
        }}
        style={{ minHeight: '3rem', height: '3rem', maxHeight: '3rem' }}
        options={
          loading
            ? [{ value: 'carregando...' }]
            : value
                ?.filter((sort: any) => sort.codigo?.toString().match(filtro))
                .map((sorteio: any) => ({
                  value: JSON.stringify(sorteio),
                  label: handleGenSorteioDescription(sorteio),
                }))
        }
      />
      <DonateTable sorteio={selectedSorteio} />
      <DonateSection
        codigo={selectedSorteio?.codigo?.toString()}
        onConfirm={console.log}
        onOpen={console.log}
        sorteio={selectedSorteio}
      />
    </Container>
  );
}

export default DonateTab;
