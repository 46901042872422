import { DialogTrigger } from '@radix-ui/react-dialog';
import { darken } from 'polished';
import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
  align-items: center;
  min-height: 100vh;
  min-width: 100vw;
  background-color: ${({ theme }) => theme.colors.secondary};
`;

export const MainContent = styled.main`
  display: flex;
  flex: 1;
  flex-direction: column;
  align-self: center;
  justify-content: center;
  width: 100%;
  max-width: 860px;
  padding: 8px 16px;

  & > div {
    display: flex;
    flex-direction: column;
    min-height: 12rem;
    width: 100%;
    border-radius: 12px;
    background: ${({ theme }) => theme.colors.shape};
    padding-bottom: 14px;
    & > * {
      padding: 0 16px;
    }
  }

  @media screen and (max-width: 355px) {
    .input-number {
      flex: 1;
      input {
        min-width: 60px;
        max-width: 60px;
      }
    }
  }
`;

export const DonateForm = styled.form`
  display: flex;
  flex-direction: column;

  section:first-of-type {
    justify-content: center;
  }

  section {
    display: flex;
    align-items: flex-end;
    flex: 1;
    width: 100%;
    margin-top: 1rem;
    flex-wrap: wrap;
    justify-content: center;
    margin: -0.5rem;
    margin-top: 0.5rem;
    & > * {
      margin: 0.5rem;
      @media screen and (max-width: 360px) {
        margin-top: 1rem;
      }
    }

    & > button {
      min-width: 4rem;
      align-items: center;
      align-self: stretch;
      @media screen and (max-width: 360px) {
        flex: 1;
      }
      &[type='submit'] {
        display: flex;
        align-items: center;
        justify-content: center;
        font-size: 1.5rem;
        & > svg {
          fill: #fff;
        }
      }
    }
  }
`;
export const PreCompraButton = styled(DialogTrigger)`
  padding: 12px 16px;
  min-width: 3rem;
  border-radius: 5px;
  font-weight: bold;
  height: 4rem;
  max-height: 4rem;
  min-height: 4rem;
  outline: none;
  border: none;
  transition: 0.42s;
  color: ${({ theme }) => theme.colors.shape};
  background: ${({ theme }) => theme.colors.primary};
  :hover {
    background: ${({ theme }) => darken(0.2, theme.colors.primary)};
  }
`;
export const Donate = styled(PreCompraButton)`
  @media screen and (max-width: 355px) {
    flex: 1;
  }
`;
