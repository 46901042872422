import styled from "styled-components";

export const Container = styled.div`
    width: 100%;

    display: flex;
    justify-content: center;
    align-items: center;

    top: 0;

    background-color: #000000a6;
    padding: 0 1rem;
    position: absolute;
    
    min-height: 100vh;
`;

export const Content = styled.div`
    background-color: #ffffff;
    width: 100%;
    position: relative;

    display: flex;
    flex-direction: column;
    align-items: center;

    border-radius: 0.5rem;
`;
export const ContentTop = styled.div`
    width: 100%;
    display: flex;
    height: 3rem;
    border-radius: 0.5rem 0.5rem 0 0;
    padding: 0.4rem 0;

    margin-bottom: 0.8rem;
`;

export const Title = styled.h1`
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;

    color: #1a202c;
    font-size: 1.3rem;
    font-weight: bold;

    padding-left: 1rem;
    
`;

export const ButtonClose = styled.button`
    width: 2rem;
    height: 2rem;

    display: flex;
    justify-content: center;
    align-items: center;

    border-radius: 50%;
    position:absolute;
    right: 0.7rem;
    top: 0.7rem;

    background: #ffffff;
    color:#d31d1d;

    transition: 0.5s;

    :hover{
        background: #d31d1d;
        color:#ffffff;
    }
`;