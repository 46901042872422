import styled from 'styled-components';

export const Container = styled.div`
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
    padding: 50px;
    hr {
        border: 0;
        clear:both;
        display:block;
        width: 96%;               
        border-top: 1px dashed #000;
        margin: 10px 0px;
    }

    
`;

export const Line = styled.div`
    width: 100vw;
    height: 4px;
    color: #000;
`;
