import styled from 'styled-components';
import Button from '../../Components/Button';
import Input from '../../Components/Form/Input';

export const Container = styled.div`
  min-height: 100vh;
  display: flex;
  flex: 1;
  flex-direction: column;
  align-items: stretch;
  background-color: ${({ theme }) => theme.colors.shape};
`;

export const Title = styled.h1`
  background-color: #203248;
  padding: 0.2rem 0;
  font-size: 1.65rem;
  font-weight: bold;
  width: 100%;
  color: ${({ theme }) => theme.colors.shape};
  text-align: center;
`;

export const CurrentCash = styled.strong`
  color: ${({ theme }) => theme.text.default};
  align-self: center;
  text-align: center;
  margin: 0.4rem 0;
`;

export const ValueSection = styled.section`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 0.2rem 0.76rem;
`;

export const ChashHistoryResume = styled.p`
  display: flex;
  flex-direction: column;
  padding: 0.2rem 0;

  span:first-of-type {
    color: ${({ theme }) => theme.colors.alert};
  }
  span {
    text-align: center;
    font-weight: 600;
  }
`;

export const ButtonModal = styled(Button)`
  align-self: flex-end;
  margin: 0 7.5% 1rem;
  padding: 0.3rem 1.3rem;
  font-size: 1.1rem;
`;

export const ContainerButton = styled.div`
  width: 85%;
  margin: 1rem 0;
  display: flex;
  justify-content: center;
`;

export const ContainerForm = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  & > *:not(:first-child) {
    margin-left: 0.6rem;
  }
`;

export const InputRecolhe = styled.input`
  padding: 0.3rem 0.8rem;
  min-width: 4rem !important;
  width: 50%;
  min-height: 2.9rem;
  outline: none;
  border: 0.1rem solid #baeef8;
  border-radius: 0.4rem;
  font-size: 1.2rem;
`;

export const ButtonRecolhe = styled.button`
  background-color: #0000ff;
  min-height: 2.9rem;

  font-size: 1.1rem;
  color: #ffffff;
  font-weight: bold;

  padding: 0.3rem 0.6rem;
  border-radius: 0.3rem;
  &.BtnUpdate{
    background-color: yellow;
    color: black;
  }
`;
