import { addSeconds, differenceInSeconds, } from "date-fns";

export const formateDate = (dataSort: Date, dataServer:Date, count:number) =>  { 

  const dSecond = differenceInSeconds(dataSort, addSeconds(dataServer, count));
  const second = dSecond % 60;
  const minute = Math.floor(dSecond / 60) % 60;
  const hour = Math.floor(Math.floor(dSecond / 60) / 60) % 24;
  const days = Math.floor(Math.floor(Math.floor(dSecond / 60) / 60) / 24);
  return (`${days} dias ${hour.toString().padStart?.(2,"0")}:${minute.toString().padStart?.(2,"0")}:${second.toString().padStart?.(2,"0")}`);
}

export const transformDate = (data:string) =>{
  const d = parseInt(data.slice(8,10));
  const m = parseInt(data.slice(5,7));
  const y = parseInt(data.slice(0,4));
  const h = parseInt(data.slice(11,13));
  const M = parseInt(data.slice(14,16));
  const s = parseInt(data.slice(17,19));

  return new Date(y,m-1,d,h,M,s);
}