import * as dialog from '@radix-ui/react-dialog';
import styled from 'styled-components';


// const overlayShow = keyframes({
//   '0%': { opacity: 0 },
//   '100%': { opacity: 1 },
// });

// const contentShow = keyframes({
//   '0%': { opacity: 0, transform: 'translate(-50%, -48%) scale(.96)' },
//   '100%': { opacity: 1, transform: 'translate(-50%, -50%) scale(1)' },
// });


export const DialogOverlay = styled(dialog.Overlay)`
  background-color: #3333;
  position: fixed;
  inset: 0;

  @media (prefers-reduced-motion: no-preference) {
    animation: 'overlayShow' 150ms cubic - bezier(0.16, 1, 0.3, 1);
  }
`

export const DialogContent = styled(dialog.Content)`
  display: flex;
  flex-direction: column;
  background-color: white;
  border-radius: 6px;
  padding-top: 10rem;
  box-shadow: hsl(206 22% 7% / 35%) 0px 10px 38px -10px, hsl(206 22% 7% / 20%) 0px 10px 20px -15px;
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  
  width: 90vw;
  max-width: 450px;
  max-height: 85vh;
  min-height: 200px;
  padding: 25px 0;
  
  & > * {
    padding: 0 25px;
  }

  

  @media (prefers-reduced-motion: no-preference) {
    animation: 'contentShow' 150ms cubic - bezier(0.16, 1, 0.3, 1);
  }
  &:focus { 
    outline: none 
  }

  & > h1, h2 {
    font-size: 1.6rem;
    font-weight: bold;
    text-align: center;
  }

  & > h2 {
    margin-top: -1rem;
    font-size: 1rem;
  }

  & > small {
    align-self: center;
  }

  & > div {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;

    margin: .6rem 0;
  }

  & > section {
    display: flex;
    margin-top: .7rem;
    align-items: center;
    justify-content: flex-end;
    & > * {
      margin-top: 0;
      &:not(:first-child) {
        margin-left: 6px;
      }
    }
    & > div {
      flex: 1;
      overflow: hidden;
    }
    
    @media screen and (max-width: 420px) {
      &.send {
        display: flex;
        flex-direction: column;
        align-items: stretch;
        & > *:not(:first-child) {
          margin: 10px 0 0;
        }
      }
    }
  } 
`

export const DialogTitle = styled(dialog.Title)`
  margin: 0;
  font-weight: 500;
  color: ${({ theme }) => theme.text.default};
  font-size: 17;
`

export const DialogDescription = styled(dialog.Description)`
  margin: 10px 0 20px;
  color: ${({ theme }) => theme.text.default};
  font-size: 15;
  line-height: 1.5;
`;

export const CloseButton = styled.button`
  all: unset;
  font-family: inherit;
  border-radius: 100%;
  height: 25px;
  width: 25px;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  color: ${({ theme }) => theme.colors.alert};
  position: absolute;
  top: 10px;
  right: 10px;

  &:hover { 
    background-color: ${({ theme }) => theme.colors.alert}; 
    color: white; 
  }
  &:focus {  
    box-shadow: 0 0 0 2px ${({ theme }) => theme.colors.alert};
  }
`

// Exports
export const Dialog = dialog.Root;
export const DialogClose = dialog.Close;
export const DialogTrigger = styled(dialog.Trigger)`
  color: ${({ theme }) => theme.text.default};
  font-weight: bold;
`;
