import React from 'react';
import { useAuth } from '../Context/auth';
import { Navigate } from 'react-router-dom';

interface PropType {
  component: React.FC;
}

export const PrivateRoute: React.FC<PropType> = ({ component: Component }) => {
  const { user } = useAuth();

  const signed = user?.token ? true : false;  
  
  if (signed) return <Component />;
  return <Navigate to='/login' />;
};


export const LoginRoute: React.FC<PropType> = ({ component: Component }) => {
  const { user } = useAuth();

  const signed = user?.token ? true : false;  
  
  if (!signed) return <Component />;
  return <Navigate to='/' />;
};




