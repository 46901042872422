import styled from 'styled-components';

export const Container = styled.div<{width?:string}>`
  display: flex;
  ${props=>`width:${props.width}`};
  margin-top: 1rem;
  max-height: 4rem;
  height: 2.8rem;
  padding: 1rem;
  transition: 0.6s;
  border: 1px solid ${({ theme }) => theme.colors.secondary};
  align-items: center;
  background: ${({ theme }) => theme.colors.deep};
  border-radius: 5px;
  
  &:focus-within {
    background: white;
    border-color: ${({ theme }) => theme.colors.primary};
  }
  
  svg {
    color: ${({ theme }) => theme.text.placeholder};
  }
  `;

export const InputComponent = styled.input`
  flex: 1;
  border: none;
  color: ${({ theme }) => theme.text.input};
  letter-spacing: 0.2px;
  background-color: transparent;
  min-height: 1rem;
  min-width: 0;
  outline: none;
  
  ::placeholder {
    color: ${({ theme }) => theme.text.placeholder};
  }
  `;

export const ContentPasswordBtn = styled.button`
  display: flex;
  justify-content: center;
  flex-direction: column;
  font-size: 1.4rem;
  outline: none;
  background-color: transparent;
  border: none;
`;