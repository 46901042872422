import styled from 'styled-components';
import Button  from '../../Components/Button';



export const ContainerPayment = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
  align-items: stretch;
  background-color: ${({ theme }) => theme.colors.shape};
  width: 100%;
  align-items: center;
  min-height: 100vh;
`;
export const TitlePayment = styled.h1`
  background-color: #203248;
  padding: .2rem 0;
  font-size: 1.65rem;
  font-weight: bold;
  width: 100%;
  color: ${({ theme }) => theme.colors.shape};
  text-align: center;
`;
const SearchContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  
  & > *:not(:first-child) {
    margin-left: .36rem;
  }
  padding: 1.26rem 0;
  & > div {
    margin: 0;
  }
`
export const ButtonPayment = styled(Button)<{opacidade: string,mouseEvent: string}> `
 opacity:${props => props.opacidade};
 pointer-events: ${props => props.mouseEvent};
 width: 50%;
 z-index: 0;
`;
export const TittlePayment = styled.h1`
font-size:  1.5rem;
color: white;
font-weight: 700;
`;
export const TittlePaymentBOLD = styled.h1`
font-size:  1.5rem;
color: white;
font-weight: 900;
`;

export const SubTittlePayment = styled.h2`
font-size:  1.4rem;
color: white;
font-weight: 700;
padding-left: 1rem;
`;
export const DivText = styled.div`
display:flex;
align-items: center;
justify-content: center;
`;

export const DivContent = styled.div`
margin: 0.5rem 0.5rem 0.5rem 0.5rem;
padding: 0.2rem;
background: #203248;
width: 100%;
border: solid 1px white;
border-radius: 10px;


`;



export {
  SearchContainer,
}