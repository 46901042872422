/* eslint-disable react/display-name */
import React, { useRef, forwardRef, MutableRefObject } from 'react';
import { IoIosAdd, IoIosRemove } from 'react-icons/io';

import { Container, ActionButton, InputComponent } from './styles';

type InputProps = React.InputHTMLAttributes<HTMLInputElement>;
type ElementRef = { element: HTMLInputElement };

export const InputNumber = forwardRef<HTMLInputElement>(
  (props: InputProps, ref) => {
    const inputRef = useRef<ElementRef>(null) as MutableRefObject<ElementRef>;

    function handleRef(element: HTMLInputElement) {
      typeof ref === 'function' && ref?.(element);
      inputRef.current = {
        element,
      };
    }

    function handleSetValue(new_value: string) {
      const input = inputRef.current?.element;
      if (input) {
        const nativeInputValueSetter = Object.getOwnPropertyDescriptor?.(
          window.HTMLInputElement.prototype,
          'value'
        )?.set;
        nativeInputValueSetter?.call(input, new_value);

        const ev2 = new Event('input', { bubbles: true });
        input.dispatchEvent(ev2);
      }
    }

    function handlePLUS() {
      const input = inputRef.current?.element;
      if (input) {
        const num = Number(input.value);
        handleSetValue((num + 1).toString());
      }
    }

    function handleMINUS() {
      if (inputRef.current?.element) {
        const num = Number(inputRef.current?.element?.value);
        if (num > 1) {
          handleSetValue((num - 1).toString());
        }
      }
    }
    return (
      <Container className="input-number">
        <ActionButton type="button" onClick={handleMINUS} name="minus">
          <IoIosRemove />
        </ActionButton>
        <InputComponent
          defaultValue={1}
          ref={handleRef}
          {...props}
          type="number"
          minLength={5}
        />
        <ActionButton type="button" onClick={handlePLUS} name="plus">
          <IoIosAdd />
        </ActionButton>
      </Container>
    );
  }
);

export default InputNumber;
