import React from 'react';
import { Container } from './PreCompraStyle';
import { useAuth } from '../../Context/auth';

export default function Login() {
  const { user } = useAuth();

  const url = `https://${user?.link_kol}/comprar`;
  console.log(url);
  return (
    <>
      <Container>
        <iframe
          style={{ height: '100%', width: '100%' }}
          src={url}
          // title="title"
        />
      </Container>
    </>
  );
}
