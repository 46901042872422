import styled from 'styled-components';
import { darken } from 'polished';

export const Container = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
  align-items: stretch;
  background-color: ${({ theme }) => theme.colors.shape};
  min-height: 100vh;
`;

export const Title = styled.h1`
  background-color: #203248;
  padding: .2rem 0;
  font-size: 1.65rem;
  font-weight: bold;
  width: 100%;
  color: ${({ theme }) => theme.colors.shape};
  text-align: center;
`;


export const Filter = styled.div`
  align-self: center;
  display: flex;
  flex-diretcition: row;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  max-width: 500px;
  margin: 10px;
  padding: 0px 10px;
  button{    
    background-color: ${({ theme }) => theme.btn.search};
    color: ${({ theme }) => theme.text.btn};
    border-radius: 4px;
    padding: 10px 20px;
    display: flex;
    margin-top: 1rem;
    min-height: 3rem;
  height: 3rem;
    align-items: center;
    :hover{
      background-color: ${({ theme }) => darken(0.01, theme.btn.search)}; 
    }
    
  }

`;

export const InputGroup = styled.div`
  width: 40%;
  
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 10px;
  span{
    font-size: 14px;
  }
  input{
    background: ${({ theme }) => theme.input.date};
    padding:10px 15px;
    font-weight: bold;
    width: 100%;
    font-size: 14px;
    text-align: center;
    border: 1px solid #D0D0D0;
    border-radius: 4px;
    min-height: 3rem;
  height: 3rem;
  font-size: 12px;  
    @media(max-width: 430px){    
      font-size: 14px;  
    }
    @media(max-width: 380px){    
      font-size: 12px;  
    }
  }
  

`;
