import React from 'react';
import {
  BsFillInboxFill,
  BsFillCartPlusFill,
  BsFillCreditCardFill,
} from 'react-icons/bs';
import { FaTicketAlt } from 'react-icons/fa';

import {
  AiFillProfile,
  AiOutlineInbox,
  AiOutlineSwap,
  AiOutlineHistory,
  AiOutlineDollarCircle,
  AiOutlineLogout,
} from 'react-icons/ai';

export const NavBarData = () => {
  // const { logout } = useContext(AuthContext);
  // function logout() {
  // window.localStorage.removeItem(REFRESH_KEY);
  // setToken(undefined);
  // if (!location.href.includes('login')) router.push('/login');
  // }

  const data = [
    // {
    //   title: 'Usuários',
    //   clickOn: '/users',
    //   icon: <AiIcons.AiOutlineUser />,
    //   cName: 'nav-text',
    // },

    {
      title: 'Doação',
      clickOn: '/',
      icon: <FaTicketAlt />,
      cName: 'nav-text',
    },
    // {
    //   title: 'Ganhadores',
    //   clickOn: '/ganhadores',
    //   icon: <AiOutlineInbox />,
    //   cName: 'nav-text',
    // },
    // {
    //   title: 'Extrato',
    //   clickOn: '/extrato',
    //   icon: <AiOutlineSwap />,
    //   cName: 'nav-text',
    // },
    {
      title: 'Recolhe',
      clickOn: '/recolhe',
      icon: <BsFillInboxFill />,
      cName: 'nav-text',
    },
    {
      title: 'Relatórios',
      clickOn: '/relatorios',
      icon: <AiFillProfile />,
      cName: 'nav-text',
    },
    // {
    //   title: 'Histórico de Doações',
    //   clickOn: '/historico-doacoes',
    //   icon: <AiOutlineHistory />,
    //   cName: 'nav-text',
    // },
    // {
    //   title: 'Histórico Por Sorteio',
    //   clickOn: '/historico-sorteio',
    //   icon: <AiOutlineHistory />,
    //   cName: 'nav-text',
    // },
    {
      title: 'Pré Compra',
      clickOn: '/pre-compra',
      icon: <BsFillCartPlusFill />,
      cName: 'nav-text',
    },

    {
      title: 'Pagar prêmio',
      clickOn: '/pagar-premio',
      icon: <AiOutlineDollarCircle />,
      cName: 'nav-text',
    },
    
    {
      title: 'Histórico de Sorteio',
      clickOn: '/historico-sorteio',
      icon: <AiOutlineHistory />,
      cName: 'nav-text',
    },
    {
      title: 'Logout',
      clickOn: '/',
      icon: <AiOutlineLogout />,
      cName: 'nav-text',
    },
  ];

  return data;
};
