import React, { ReactNode } from 'react';

import { Container } from './styles';

export type Op = {
  value: string;
  label?: string | undefined;
}

interface SelectInputProps
  extends React.SelectHTMLAttributes<HTMLSelectElement> {
  children?: ReactNode;
  options?: Op[];
}

export function SelectInput({ children, options, ...props }: SelectInputProps) {
  return (
    <Container {...props}>
      {children}
      {options?.map((op) => (
        <option key={op.value} value={op.value}>
          {op.label || op.value}
        </option>
      ))}
    </Container>
  );
}
