import styled from 'styled-components'
import * as TabsPrimitive from '@radix-ui/react-tabs'

const StyledTabs = styled(TabsPrimitive.Root)`
  display: flex;
  flex-direction: column;
  width: 300;
  `;

const StyledList = styled(TabsPrimitive.List)`
  flex-shrink: 0;
  display: flex;
  border-bottom: 1px solid ${({theme}) => theme.colors.deep};
  min-height: 3rem;
  padding: 0px !important;
`;

const StyledTrigger = styled(TabsPrimitive.Trigger)`
  all: unset;
  font-family: inherit;
  padding: 0 20px;
  height: 45;
  flex: 1;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 15;
  line-height: 1;
  color: ${({theme}) => theme.text.placeholder};
  user-select: none;
  outline: none;
  
  &:first-child { border-top-left-radius: 6px };
  &:last-child { border-top-right-radius: 6px };
  
  &:hover { color: ${({theme}) => theme.colors.sucess} };  
  
  &[data-state="active"] {
    color: ${({theme}) => theme.colors.sucess};
    box-shadow: inset 0 -1px 0 0 currentColor, 0 1px 0 0 currentColor;
  };
  
  &:focus { 
    outline: none;
    position: relative; 
  };
`;

const StyledContent = styled(TabsPrimitive.Content)`
  display: flex;
  flex-direction: column;
`;

// Exports
export const Tabs = StyledTabs;
export const TabsList = StyledList;
export const TabsTrigger = StyledTrigger;
export const TabsContent = StyledContent;