import styled from 'styled-components';

interface CartsProps{
    type: string;
}

export const Container = styled.div`
    width: 100%;
    
`;

export const Wrapper = styled.div<CartsProps>`
  display: grid;
  grid-template-columns: ${props => props.type == 'L' ? 'repeat(2, 1fr)' : 'repeat(1, 1fr)' } ;
  width: 100%;

`;

export const Item = styled.div`
    /* display: grid;
    grid-template-columns: repeat(5, 1fr); */
    width: 50%;
    display: flex;
    flex-direction: column;
    font-size: 12px;
    margin-bottom: 10px;
    h3{
        font-size: 16px;
       
    }
    
   
`;

export const Numbers = styled.div`
   
`;

export const Row = styled.div`
    /* display: flex;
    flex-direction: row; */
    width: 100%;
    display: grid;
    grid-template-rows: repeat(5, 0fr); 
`;