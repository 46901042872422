import { AxiosError } from "axios"
import { v5 } from "./Api"
import handleError from '../utils/handleError';

export const recolhimentoPost = async (val: number, tipe: "T" | "A", passWord: string) => {
    const res = await v5.post(`/solicitacao_recolhe/`, {
        valor: val,
        tipo: tipe,
        senha: passWord

    }).catch((err) => err as AxiosError)

    if (!(res instanceof Error)) {
        return {data:res.data.detail}
    } else {
        return res?.response?.status == 403 || res?.response?.status == 401 ? { error: "Sessão inválida, Faça logout"} : {error: res.response?.data.detail};
    }

}

export const recebimentosGet = async (id: number) => {
    const res = await v5.get<IRecebimentos>(`recebimentos/${id}/`)
    .then(({ data }) => data)
    .catch((err) => err as AxiosError);

    if (res instanceof Error) {
        handleError(res?.response?.status, res?.response?.data?.details, res?.response?.data?.detail);
        return { error: res };
    }
    return { data: res };
  
}

export const requestPayment = async (sorteio_id: string, codigo: string, senha: string) => {
    const payload = {
        sorteio_id: sorteio_id,
        codigo: codigo,
        senha: senha,
    };

    const res = await v5
        .post<IBilhetePremiado>('/pagar_premio/', payload)
        .then(({ data }) => data)
        .catch((err) => err as AxiosError);

    if (res instanceof Error) {
        handleError(res?.response?.status, res?.response?.data?.details, res?.response?.data?.detail);
        return { error: res };
    }
    return { data: res };
};

export const requestSeachTicket = async (bilhete: string) => {
    const res = await v5.get<IPagamento>(`consulta_premio/${bilhete}/`)
        .then(({ data }) => data)
        .catch((err) => err as AxiosError);

    if (res instanceof Error) {
        handleError(res?.response?.status, res?.response?.data?.details, res?.response?.data?.detail);
        return { error: res };
    }
    return { data: res };

};


export const requestDonate = async (sorteio: string, qtd_cartelas: number) => {
    const payload = {
        sorteio,
        qtd_cartelas
    };
    const res = await v5.post<IBilhete>('/comprar_cartela/', payload)
        .catch((err) => err as AxiosError);

    if (res instanceof Error) {
        handleError(res?.response?.status, res?.response?.data?.details, res?.response?.data?.detail);
        return { error: res };
    }
    return { data: res };

}

export const requestConfirmPreSale = async (codigo: string) => {
    const payload = {
        codigo
    };
    const res = await v5.post<IBilheteLote>('/confimar_pre_compra/', payload)
        .catch((err) => err as AxiosError);

    if (res instanceof Error) {
        handleError(res?.response?.status, res?.response?.data?.details, res?.response?.data?.detail);
        return { error: res };
    }
    return { data: res };

}

export const requestPreSaleDetails = async (code: string) => {

    const res = await v5.get<PreCompra>(`/pre_compra_details/${code}/`)
        .catch((err) => err as AxiosError);

    if (res instanceof Error) {
        handleError(res?.response?.status, res?.response?.data?.details, res?.response?.data?.detail);
        return { error: res };
    }
    return { data: res };

}


export const requestGetNextGames = async () => {

    const res = await v5.get<SorteioData[]>('/proximos_sorteios/')
        .catch((err) => err as AxiosError);

    if (res instanceof Error) {
        handleError(res?.response?.status, res?.response?.data?.details, res?.response?.data?.detail);
        return { error: res };
    }
    return { data: res };

}

export const getHistory = async (inicio: string, fim: string) => {
    const res = await v5.get<IHistorico>(`movimentacao/?inicio=`+inicio+"&fim="+fim)
        .catch((err) => err as AxiosError)
    if (!(res instanceof Error)) {
        return res.data
    } else {
        handleError(res?.response?.status, res?.response?.data?.details, res?.response?.data?.detail);
    }
}

