import styled from "styled-components";

export const Container = styled.div`
    width: 95%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;

`;

export const Imagem = styled.img`
    width: 4rem;
    margin-bottom: 1rem;
`;

export const Title = styled.h1`
    font-size: 1.2rem;
    font-weight: bold;
    background-color: #FCFCFC;
    padding: 0 1rem;
    border-radius: 0.2rem;
`;
