import React, { useEffect, useRef, useState } from 'react';
import Input from '../Form/Input';
import Button from '../Button';
import { useForm } from 'react-hook-form';
import { useAsyncFn } from 'react-use';
import {
  requestConfirmPreSale,
  requestPreSaleDetails,
} from '../../Services/resquestsV5';
import Table2 from '../TableV2';
import { Separator } from '../DonateTable/styles';
import { compartilharPreMsg } from '../../utils/compartilhar';
import { useAuth } from '../../Context/auth';
import { TabsContentProps } from '@radix-ui/react-tabs';
import { Container, Form, ButtonSearch } from './styles';
import { IoSearch } from 'react-icons/io5';

import InputMask from 'react-input-mask';

import { toast } from 'react-toastify';
import { useReactToPrint } from 'react-to-print';
import PrintPreSale from '../Print/PrintPreSale';

interface SubmitData {
  code?: string;
  phone?: string;
}

interface DataFetchPayload {
  deviceInfo?: DeviceInfo;
  payload: {
    codigo: string;
  };
  user?: IUser;
  phone: string;
}

function ConfirmPreCompraModal(props: TabsContentProps) {
  const { user, deviceInfo } = useAuth();
  const [bilheteResponse, setBilheteResponse] = useState<IBilheteLote>();
  const [userDeviceData, setUserDeviceData] = useState<IUser & DeviceInfo>();
  const { handleSubmit, register, reset } = useForm<SubmitData>();
  const componentRef = useRef<HTMLDivElement>(null);
  const [message, setMessage] = useState('');
  const [preCompra, setPrecompra] = useAsyncFn(async (code?: string) => {
    if (!code) throw new Error('Invalid code!');
    const { data } = await requestPreSaleDetails(code);
    if (data) {
      console.log(data.data);
      return data.data;
    }
  }, []);

  useEffect(() => {
    if (!preCompra.loading && preCompra.error) {
      toast.error('Erro ao finalizar pule!');
    }
  }, [preCompra]);

  async function handleFetchData({
    deviceInfo,
    payload,
    user,
    phone,
  }: DataFetchPayload) {
    const { data } = await requestConfirmPreSale(payload.codigo);
    if (!data) throw new Error();
    try {
      const userData = {
        ...(user as IUser),
        ...(deviceInfo as DeviceInfo),
      };

      setBilheteResponse(data.data);
      setUserDeviceData(userData);
      const message = compartilharPreMsg(
        data.data,
        userData,
        process.env.VERSION || 'beta'
      );
      setMessage(message);
      const url = `https://api.whatsapp.com/send?phone=55${phone}&text=${encodeURI(message)}`;

      // O uso do setTimeout foi realizado para corrigir um problema do sistema IOS
      setTimeout(() => { 
        window.open(url, '_blank')
      })
      
    } catch {
      reset();
    }
  }

  async function handleDonate({ phone }: any) {
    const payload = {
      codigo: String(preCompra.value?.codigo),
    };
    console.log(payload, user);
    console.log(deviceInfo);
    console.log(user);
    console.log(phone);
    try {
      await handleFetchData({
        payload,
        deviceInfo,
        user,
        phone,
      });
    } catch (err: any) {
      if (err?.response?.data?.details)
        toast.error(err?.response?.data?.details);
      else toast.error('Erro na doação.');
      reset({
        phone: '',
      });
    } finally {
      reset({
        phone: '',
      });
    }
  }

  const handlePrint = useReactToPrint({
    content: () => componentRef.current,
  });

  return (
    <Container {...props}>
      <Form onSubmit={handleSubmit((data) => setPrecompra(data.code))}>
        <Input
          autoComplete="off"
          type="number"
          id="code"
          placeholder="Digite o seu código de pré-compra"
          {...register('code')}
        />

        <ButtonSearch>
          <IoSearch />
        </ButtonSearch>
      </Form>

      <div>
        <span>
          Valor Total: R${' '}
          {preCompra.value?.valor_total.toLocaleString('pt-br', {
            minimumFractionDigits: 2,
          })}
        </span>
        <span>
          Status: {preCompra.value?.confirmado ? 'Confirmado' : 'Em espera'}
        </span>
      </div>
      <Table2
        columns={{
          sorteio: 'Sorteio:',
          qtd_cartelas: 'Quantidade:',
          valor_bilhete: 'Valor',
        }}
        data={
          preCompra.value?.bilhetes.map((b) => ({
            ...b,
            valor_bilhete:
              'R$ ' +
              b.valor_bilhete.toLocaleString('pt-br', {
                minimumFractionDigits: 2,
              }),
          })) ||
          [
            // { sorteio: 0, qtd_cartelas: 0, valor_bilhete: '--' },
            // { sorteio: 0, qtd_cartelas: 0, valor_bilhete: '--' },
            // { sorteio: 0, qtd_cartelas: 0, valor_bilhete: '--' },
            // { sorteio: 0, qtd_cartelas: 0, valor_bilhete: '--' },
          ]
        }
      />
      <Separator orientation="horizontal" />
      {preCompra.value && (
        <Form onSubmit={handleSubmit(handleDonate)}>
          <InputMask
            type="number"
            mask="(99) 99999-9999"
            autoFocus={true}
            placeholder={'(99) 99999-9999'}
            color="black"
            autoComplete="off"
            {...register('phone')}
          >
            {(inputProps: any) => <Input {...inputProps} />}
          </InputMask>
          <Button type="submit">Enviar</Button>
        </Form>
      )}

      {userDeviceData && bilheteResponse && (
        <>
          <Button onClick={handlePrint} style={{ marginTop: '10px' }}>
            Imprimir
          </Button>
          <Button
            onClick={() => navigator.clipboard.writeText(message)}
            style={{ marginTop: '10px' }}
          >
            Copiar Impressão
          </Button>
          <div style={{ display: 'none' }}>
            <div ref={componentRef}>
              <PrintPreSale
                lote={bilheteResponse}
                userInfo={userDeviceData}
                version={process.env.VERSION || 'beta'}
              />
            </div>
          </div>
        </>
      )}
    </Container>
  );
}

export default ConfirmPreCompraModal;
