import React, { ForwardedRef, forwardRef, useState } from 'react';
import { IoIosEye, IoIosEyeOff } from 'react-icons/io';

import { Container, ContentPasswordBtn, InputComponent } from './styles';

type InputProps = React.InputHTMLAttributes<HTMLInputElement>;

function InputComp(props: InputProps, ref: ForwardedRef<HTMLInputElement>, width?:string) {
  const isPassworType = props.type === 'password' || props.id === 'password';
  const [passwordVisible, setPasswordVisible] = useState(!isPassworType);
  return (
    <Container width={width}>
      <InputComponent {...props} ref={ref} type={passwordVisible ? 'text' : 'password'} />
      {isPassworType && (
        <ContentPasswordBtn
          type="button"
          onClick={() => setPasswordVisible(!passwordVisible)}
        >
          {passwordVisible ? <IoIosEye /> : <IoIosEyeOff />}
        </ContentPasswordBtn>
      )}
    </Container>
  );
}

const Input = forwardRef(InputComp);

export default Input;
