import styled from 'styled-components';

export const Container = styled.table`
  width: 100vw;
  thead {
    background-color: #203248;
    color: ${({ theme }) => theme.colors.shape};
  }

  tr {
    width: 100%;
  }

  th {
    padding: .6rem .3rem;
    width: 20%;    
    
  }
  
  td {
    padding: .3rem .2rem;
    
    &:first-of-type {
      padding-left: 1rem;
    }

    &:not(:first-child) {
      text-align: center;
    }

  }
  
  tbody tr:not(:nth-child(odd)){
    background-color: ${({theme}) => theme.colors.secondary}59;
  }
 

`;
