import React from 'react';

import { Container } from './styles';

import { getPreServer } from '../../../Services/Api';
import QRCode from 'react-qr-code';
import Carts from '../Carts';

interface IPrintDonate {
  bilhete: IBilhete;
  userInfo: IUser & DeviceInfo;
  version: string;
}

const PrintDonate: React.FC<IPrintDonate> = ({
  bilhete,
  userInfo,
  version,
}) => {
  function formatDate(date: Date, option?: Intl.DateTimeFormatOptions) {
    return date.toLocaleDateString('pt-bt', option);
  }

  function formatTime(date: Date, option?: Intl.DateTimeFormatOptions) {
    return date.toLocaleTimeString('pt-bt', option);
  }

  return (
    <Container>
      <p>LOCAL:* {bilhete.cartelas[0].estabelecimento}*</p>

      <hr />

      <p>
        SORTEIO: * {bilhete.tipo_rodada || ''}* * {bilhete.sorteio}* * DATA:{' '}
        {formatDate(new Date(bilhete.data_partida))}*
      </p>

      <hr />

      <p>
        POS: {userInfo.pos.id} - {userInfo.mac_address?.slice(12)} {version}
      </p>
      <p>VENDEDOR: {userInfo.usuario.nome || ''}</p>
      <p>FRANQUIA: {getPreServer()}</p>
      <p>CLIENTE: {userInfo.estabelecimento.rota}</p>
      <p>VALOR CARTELA: {bilhete.valor_cartela.toFixed(2)}</p>
      <p>QTD DE CARTELA: {bilhete.cartelas.length}</p>
      <p>BILHETE: {bilhete.bilhete}</p>
      <p>EMISSÃO: {formatTime(new Date())}</p>

      <hr />

      <p>
        VALOR DOADO: *{' '}
        {(bilhete.valor_cartela * bilhete.cartelas.length).toFixed(2)}*
      </p>

      <hr />

      {bilhete.tipo_impressao == 'L' ||
        (bilhete.tipo_impressao == 'I' && (
          <Carts carts={bilhete.cartelas} type={bilhete.tipo_impressao} />
        ))}

      <hr />

      <p>
        SEQUÊNCIA DE:* {bilhete.cartelas[0]?.codigo} a{' '}
        {bilhete.cartelas?.[bilhete.cartelas?.length - 1]?.codigo}*
      </p>

      <hr />

      <p>Acesse o link para acompanhar o jogo:</p>
      <p>
        https://{userInfo?.url_qrcode}/?bilhete={bilhete.bilhete}`
      </p>

      {bilhete.imprimir_qrcode && (
        <p>
          <QRCode
            value={`https://${userInfo?.url_qrcode}/?bilhete=${bilhete.bilhete}`}
          />
        </p>
      )}
    </Container>
  );
};

export default PrintDonate;
